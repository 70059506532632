































































































































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import AccountDetailCard from '../auth/account-detail-card.vue'
import Avatar from '@/modules/community/common/components/avatar.vue'
import { accountSettingController } from '../auth/account-setting-controller'
import { walletSettingController } from '../auth/wallet-setting-controller'

@Observer
@Component({
  components: {
    Avatar,
    AccountDetailCard,
    'connect-wallet-dialog': () => import('../navbar/connect-wallet-dialog.vue'),
  },
})
export default class ConnectWallet extends Vue {
  wallet = walletStore
  showAccountDetail = false
  accountSettingController = accountSettingController
  walletSettingController = walletSettingController

  async copyAddress() {
    if (this.wallet.account) {
      await navigator.clipboard.writeText(this.wallet.account)
    }
  }
}
